import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Params, Router, RouterState} from '@angular/router';

@Component({
  selector: 'app-stream-container',
  templateUrl: './stream-container.component.html',
  styleUrls: ['./stream-container.component.scss']
})
export class StreamContainerComponent implements OnInit {

  names = [
    'spaziogames',
    'xqcow',
    'hollywood285',
    'adrianfartade',
    'yotobi',
    'ilgattosultubo',
    'marcomerrino',
    'tcuesport'
  ];

  videos = [
    '889961730',
    '889960789',
    '887431597',
    '883409126'
  ];

  constructor(private route: ActivatedRoute, private router: Router) {}

  ngOnInit(): void {
  }

  // tslint:disable-next-line:typedef
  public openNewTab(url) {
    window.open(url, '_blank');
  }

  // tslint:disable-next-line:typedef
  goToItems() {
    this.router.navigate(['/heroes', {id: this.videos[0]}]).then(r => '');
  }
}
