<div class="container">
  <div class="carousel-container">
  </div>

  <h2 style="text-align:center">Slideshow Gallery</h2>

  <!-- <div class="container">
    <div class="mySlides">
      <div class="numbertext">{{this.slide + 1}} / 6</div>
      <img src="{{this.path}}{{this.tournaments[this.slide].photo}}">
    </div>

    <a class="prev" (click)="sliderCarousel(true)">❮</a>
    <a class="next" (click)="sliderCarousel(false)">❯</a>

    <div class="caption-container">
      <p id="caption">{{this.tournaments[this.slide].title}}</p>
    </div>

    <div class="row">
      <div *ngFor="let slider of tournaments">
        <div class="column">
          <img class="demo cursor"
               src="{{this.path}}{{slider.photo}}"
               style="width:100%" alt="{{slider.title}}"
               (click)="this.slide = slider.n"
          >
        </div>
      </div>
    </div>
  </div>
    <app-carousel-cell [names]="names" [videoType]="1"></app-carousel-cell>
 -->
    <div class="box-container">
      <div class="left">
        <app-chat-container [chatId]="[18, 19, 20]" [chatType]="'wangameschannel'"></app-chat-container>
      </div>
      <div class="main">

      </div>
    </div>
</div>
<app-footer></app-footer>

