import { Component, ElementRef, Input, OnChanges, OnInit, Renderer2, ViewChild} from '@angular/core';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnChanges {

  @Input() chatId: number;
  @Input() chatType: string;
  @ViewChild('script', {static: true}) script: ElementRef;

  constructor(
    private renderer: Renderer2
  ) {}

  n = 0;

  convertToScript(chat: any): void {
    const element = this.script.nativeElement;
    const script = document.createElement('script');
    script.src = 'https://telegram.org/js/telegram-widget.js?14';
    script.setAttribute('data-telegram-discussion', this.chatType + '/' + this.chatId);
    script.setAttribute('data-comments-limit', '5');
    script.setAttribute('data-colorful', '1');
    this.renderer.appendChild(chat, script);
  }

  childElement(): void {
    if (this.n >= 1) { document.getElementById('chat' + (this.n - 1)).remove(); }

    const chat = this.renderer.createElement('div');
    this.renderer.setProperty(chat, 'id', 'chat' + this.n);
    this.renderer.appendChild(this.script.nativeElement, chat);

    this.convertToScript(chat);

    this.n ++;
  }

  ngOnChanges(): void {
    this.childElement();
    console.log(this.chatId);
  }

}
