import {Component, HostListener, OnInit} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';

@Component({
  selector: 'app-live',
  templateUrl: './live.component.html',
  styleUrls: ['./live.component.scss']
})
export class LiveComponent implements OnInit {

  names = [
    'spaziogames',
    'xqcow',
    'hollywood285',
    'adrianfartade',
    'yotobi',
    'ilgattosultubo',
    'marcomerrino'
  ];

  live: any;
  private href: any;

  constructor(private route: ActivatedRoute) {
  }

  innerWidth: any;
  innerHeight: any;

  // tslint:disable-next-line:typedef
  ngOnInit() {
    this.innerWidth = window.innerWidth;
    this.innerHeight = window.innerHeight;
    console.log(this.innerWidth);
    this.route.params.subscribe((params: Params) => {
      this.live = params.live; // same as :username in route
    });
  }

  // tslint:disable-next-line:typedef
  typeof() {
    return !isNaN(Number(this.live));
  }
}
