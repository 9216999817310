<div class="container">
  <div class="box-container">
    <div class="left">
      <app-chat-container [chatId]="[18, 19, 20]" [chatType]="'wangameschannel'"></app-chat-container>
    </div>
    <div class="main">
      <div *ngIf="typeof(); else elseBlock">
        <app-video [videoType]="5" [idVideo]="live" [wpx]="innerWidth - 373" [hpx]="innerHeight - 80"></app-video>
      </div>
      <ng-template #elseBlock>
        <app-video [videoType]="4" [channel]="live" [wpx]="innerWidth - 373" [hpx]="innerHeight - 80"></app-video>
      </ng-template>
    </div>
  </div>
  <div>
    <app-carousel-cell [names]="names" [videoType]="1"></app-carousel-cell>
  </div>
</div>
<app-footer></app-footer>
