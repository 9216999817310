import {Component, ElementRef, HostListener, Input, OnInit, Renderer2, ViewChild} from '@angular/core';
import {TwitchEmbed, TwitchEmbedLayout, TwitchEmbedTheme, TwitchPlayer} from 'twitch-player';

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss']
})
export class VideoComponent implements OnInit {

  @Input() videoType: number;
  @Input() channel: string;
  @Input() id: number;
  @Input() idVideo: string;
  @Input() wpx: number;
  @Input() hpx: number;

  declare Twitch: any;
  embed: any;
  theme: TwitchEmbedTheme = TwitchEmbedTheme.LIGHT;
  @ViewChild('script', {static: true}) script: ElementRef;

  constructor(private renderer: Renderer2) { }

  ngOnInit(): void {
    this.startTwitch();
  }

  // tslint:disable-next-line:typedef
  live(booVideo: TwitchEmbedLayout, theme: TwitchEmbedTheme, hpx: number, wpx: number) {
    window.setTimeout(() => {
      this.embed = new TwitchEmbed('twitch' + this.videoType + this.id, {
        width: wpx,
        height: hpx,
        autoplay: true,
        channel: this.channel,
        layout: booVideo,
        theme
      }).setVolume(0);
      }, 100);
  }

  // tslint:disable-next-line:typedef
  oldLive(booVideo: TwitchEmbedLayout, theme: TwitchEmbedTheme, hpx: number, wpx: number) {
    window.setTimeout(() => {
      this.embed = new TwitchEmbed('twitch' + this.videoType + this.id, {
        width: wpx,
        height: hpx,
        autoplay: true,
        video: this.idVideo,
        layout: booVideo,
        theme
      });
    }, 100);
  }

  // tslint:disable-next-line:typedef
  onlyVideo() {
    window.setTimeout(() => {
      const player = TwitchPlayer.FromOptions('twitch' + this.videoType + this.id, {
        width: 200,
        height: 200,
        autoplay: false,
        video: this.idVideo
      });
    }, 100);
  }

  // tslint:disable-next-line:typedef
  startTwitch() {
    switch (this.videoType) {
      case 1:
        this.live(TwitchEmbedLayout.VIDEO, this.theme, 200, 200);
        break;
      case 2:
        this.onlyVideo();
        break;
      case 3:
        this.onlyVideo();
        break;
      case 4:
        this.live(TwitchEmbedLayout.VIDEO_WITH_CHAT, this.theme, this.hpx, this.wpx);
        break;
      case 5:
        this.oldLive(TwitchEmbedLayout.VIDEO_WITH_CHAT, this.theme, this.hpx, this.wpx);
        break;
    }
  }

  /*@HostListener('window:resize', ['$event'])
  // tslint:disable-next-line:typedef
  onResize(event) {
    this.wpx = window.innerWidth;
    this.hpx = window.innerHeight;
    document.getElementById('twitch' + this.videoType + this.id).remove();
    const live = this.renderer.createElement('div');
    this.renderer.setProperty(live, 'id', 'twitch' + this.videoType + this.id);
    this.renderer.appendChild(this.script.nativeElement, live);
    this.startTwitch();
  }*/

}
