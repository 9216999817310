import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';

@Component({
  selector: 'app-share-button',
  templateUrl: './share-button.component.html',
  styleUrls: ['./share-button.component.scss']
})
export class ShareButtonComponent implements OnInit {

  @Input() src: string;
  @Input() size: string;
  @Input() text: string;
  @ViewChild('script', {static: true}) script: ElementRef;

  constructor() { }

  ngOnInit(): void {
    this.convertToScript();
  }

  // tslint:disable-next-line:typedef
  private convertToScript() {
    const element = this.script.nativeElement;
    const script = document.createElement('script');
    script.src = 'https://telegram.org/js/telegram-widget.js?14';
    script.setAttribute('data-telegram-share-url', this.src);
    script.setAttribute('data-size', this.size);
    script.setAttribute('data-text', this.text);
    element.parentElement.replaceChild(script, element);
  }

  /* Example
        <app-share-button [src]="'http://80.211.56.166/'" [size]="'large'" [text]="'notext'"></app-share-button>
  */
}
