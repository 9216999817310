<div class="footer-container">

  <section [class.notLanding]="(this.router.url !== '/landingpage')">

    <svg class="wave" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
      <path fill="#B45CE3" fill-opacity="1"
            d="M0,128L40,138.7C80,149,160,171,240,192C320,213,400,235,480,213.3C560,192,640,128,720,128C800,128,880,192,960,213.3C1040,235,1120,213,1200,181.3C1280,149,1360,107,1400,85.3L1440,64L1440,320L1400,320C1360,320,1280,320,1200,320C1120,320,1040,320,960,320C880,320,800,320,720,320C640,320,560,320,480,320C400,320,320,320,240,320C160,320,80,320,40,320L0,320Z"
            data-darkreader-inline-fill="" style="--darkreader-inline-fill:#007acc;"></path>
    </svg>

  </section>

  <div class="end-footer">
    <div>
      <h1>{{this.footerObj.text1}}</h1>
      <h1>{{this.footerObj.text2}}</h1>
    </div>
    <img class="mobile" src="{{this.footerObj.img}}" alt="">
  </div>
</div>
