<div class="container">
  <app-carousel-cell [names]="names" [videoType]="1"></app-carousel-cell>
  <div class="twitch-box old-live">
    <div class="title">
      <h1><span>LIVE</span> PASSATE</h1>
    </div>
    <div class="twitch-video first">
      <app-carousel-cell [videos]="videos" [videoType]="2"></app-carousel-cell>
    </div>
    <div class="twitch-video">
      <app-carousel-cell [videos]="videos" [videoType]="3"></app-carousel-cell>
    </div>
  </div>
</div>
<app-footer style="margin-top: -25rem;"></app-footer>

