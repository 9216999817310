<div class="chat-container">
  <div class="ui secondary pointing menu">
    <a *ngIf="chatId[0]" class="item" [class.active]="this.chat == this.chatId[0]" (click)="this.chat = this.chatId[0]">
      <i class="globe europe icon"></i>
    </a>
    <a *ngIf="chatId[1]" class="item" [class.active]="this.chat == this.chatId[1]" (click)="this.chat = this.chatId[1]">
      <i class="huge icons">
        <i class="comment alternate outline icon"></i>
        <i class="bottom right corner users icon"></i>
      </i>
    </a>
    <a *ngIf="chatId[2]" class="item" [class.active]="this.chat == this.chatId[2]" (click)="this.chat = this.chatId[2]">
      <i class="gamepad icon"></i>
    </a>
    <div class="right menu">
      <a class="item"
         (click)="expandWindow($event)">
        <i class="expand icon"></i>
      </a>
    </div>
  </div>
  <div class="chat">
    <app-chat [chatId]="this.chat" [chatType]="chatType"></app-chat>
  </div>
</div>
