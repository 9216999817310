import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';

@Component({
  selector: 'app-home-containers',
  templateUrl: './home-containers.component.html',
  styleUrls: ['./home-container.component.scss']
})
export class HomeContainersComponent implements OnInit {

  constructor() {
  }

  // path = 'assets/img/';
  path = '';
  slide = 0;
  img = 'https://www.gamerpro.eu/files/images/2051cfd79481b2c4378c6a044ec8f960.png';

  tournaments = [
    {
      title: 'uno',
      photo: this.img,
      n: 0
    },
    {
      title: 'due',
      photo: 'https://www.gamerpro.eu/files/images/3f7489cfedaee63391da1b17105b16c8.jpeg',
      n: 1
    },
    {
      title: 'tre',
      photo: this.img,
      n: 2
    },
    {
      title: 'quattro',
      photo: this.img,
      n: 3
    },
    {
      title: 'cinque',
      photo: this.img,
      n: 4
    },
    {
      title: 'sei',
      photo: this.img,
      n: 5
    },
  ];

  names = [
    'spaziogames',
    'xqcow',
    'hollywood285',
    'adrianfartade',
    'yotobi',
    'ilgattosultubo',
    'marcomerrino'
  ];
  slideIndex = 1;

  // tslint:disable-next-line:typedef
  ngOnInit() {
  }

  // tslint:disable-next-line:typedef
  sliderCarousel(bo: boolean) {
    if (bo) {
      ( this.slide > 0 ) ? this.slide -= 1 : this.slide = 5;
    } else {
      ( this.slide < 5 ) ? this.slide += 1 : this.slide = 0;
    }
  }

}
