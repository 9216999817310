<div class="ui secondary menu container">
  <div class="ui secondary menu">
    <a class="item logo" routerLink="/landingpage">
      <img src="./assets/img/logo.PNG" class="ui circular image" alt="">
    </a>
    <a class="item" routerLink="/homepage" routerLinkActive="active">
      Home
    </a>

    <a class="item" routerLink="/homepage" routerLinkActive="active">
      Condizioni
    </a>
    <a class="item">
      Privacy
    </a>
    <a class="item">
      Cookie
    </a>
  </div>
  <div class="right menu">
    <div class="social-container">

      <button class="ui circular twitter icon button">
        <i class="twitter icon"></i>
      </button>
      <button class="ui circular instagram icon button">
        <i class="instagram icon"></i>
      </button>
      <button class="ui circular facebook icon button">
        <i class="facebook icon"></i>
      </button>
      <button class="ui circular youtube icon button">
        <i class="youtube icon"></i>
      </button>
      <button class="ui circular twitter icon button">
        <i class="twitch icon"></i>
      </button>
      <button class="ui circular discord icon button">
        <i class="discord icon"></i>
      </button>
    </div>
    <a class="ui item">
      Tutti i diritti riservati.
    </a>
  </div>
</div>
