import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeContainersComponent } from './containers/home-container/home-containers.component';
import { ChatComponent } from './shared/component/tg/chat/chat.component';
import { SidebarComponent } from './shared/component/sidebar/sidebar.component';
import { NavbarContainerComponent } from './containers/navbar-container/navbar-container.component';
import { ContainersComponent } from './containers/containers.component';
import { LandingpageContainerComponent } from './containers/landingpage-container/landingpage-container.component';
import { HeaderComponent } from './containers/landingpage-container/features/header/header.component';
import { FooterContainerComponent } from './containers/footer-container/footer-container.component';
import { FooterComponent } from './containers/landingpage-container/features/footer/footer.component';
import { ShareButtonComponent } from './shared/component/tg/share-button/share-button.component';
import { StreamContainerComponent } from './containers/stream-container/stream-container.component';
import { VideoComponent } from './shared/component/twitch/video/video.component';
import { ChatContainerComponent } from './shared/component/tg/chat-container/chat-container.component';
import {IvyCarouselModule} from 'angular-responsive-carousel';
import { LiveComponent } from './shared/component/twitch/live/live.component';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { CarouselCellComponent } from './shared/component/carousel-cell/carousel-cell.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeContainersComponent,
    ChatComponent,
    SidebarComponent,
    NavbarContainerComponent,
    ContainersComponent,
    LandingpageContainerComponent,
    HeaderComponent,
    FooterContainerComponent,
    FooterComponent,
    ShareButtonComponent,
    StreamContainerComponent,
    VideoComponent,
    ChatContainerComponent,
    LiveComponent,
    CarouselCellComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    IvyCarouselModule
  ],
  providers: [{provide: LocationStrategy, useClass: HashLocationStrategy}],
  bootstrap: [AppComponent]
})
export class AppModule { }
