<div class="ui vertical sidebar menu">
  <a class="item">
    <img src="assets/img/logo.PNG">
  </a>
  <a class="item">
    <i class="home icon"></i>
    Home
  </a>
  <a class="item">
    <i class="block layout icon"></i>
    Topics
  </a>
  <a class="item">
    <i class="smile icon"></i>
    Friends
  </a>
</div>

<!-- Button -->
<button class="btn ui circular twitter icon button" (click)="openSidebar()">
  <i class="gamepad large icon"></i>
</button>
