import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  footerObj = {
    text1: 'Cosa aspetti?',
    text2: (' ' + 'Unisciti alla battaglia'),
    color: 'fff',
    color2: 'B45CE3',
    img: './assets/img/rainbow1.PNG',
    imgBackground: 'https://www.smartworld.it/wp-content/uploads/2020/09/Call-of-Duty-Black-Ops-Cold-War-multiplayer-14-1280x720.jpg'
  };

  constructor(public router: Router) { }

  ngOnInit(): void {}

  // tslint:disable-next-line:typedef
  setStyles() {
    return {};
  }

}
