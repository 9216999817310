import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-carousel-cell',
  templateUrl: './carousel-cell.component.html',
  styleUrls: ['./carousel-cell.component.scss']
})
export class CarouselCellComponent implements OnInit {

  @Input() videos: string[];
  @Input() names: string[];
  @Input() videoType: number;

  constructor() { }

  ngOnInit(): void {
  }

}
