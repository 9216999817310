import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-chat-container',
  templateUrl: './chat-container.component.html',
  styleUrls: ['./chat-container.component.scss']
})
export class ChatContainerComponent implements OnInit {

  @Input() chatId: number[];
  @Input() chatType: string;

  chat: number;

  constructor() { }

  ngOnInit(): void {
    switch (true) {
      case !isNaN(this.chatId[2]):
        this.chat = this.chatId[2];
        break;

      case !isNaN(this.chatId[1]):
        this.chat = this.chatId[1];
        break;

      case !isNaN(this.chatId[0]):
        this.chat = this.chatId[0];
        break;
    }
  }

  // tslint:disable-next-line:typedef
  expandWindow(event: MouseEvent) {
    window.open('https://t.me/' + this.chatType + '/' + this.chat + '?embed=1&discussion=1&comments_limit=5&colorful=1', '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');
  }
}
