import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {LandingpageContainerComponent} from './containers/landingpage-container/landingpage-container.component';
import {HomeContainersComponent} from './containers/home-container/home-containers.component';
import {StreamContainerComponent} from './containers/stream-container/stream-container.component';
import {LiveComponent} from './shared/component/twitch/live/live.component';

const routes: Routes = [
  // { path: '**', component: LandingpageContainerComponent },
  { path: '', redirectTo: 'landingpage', pathMatch: 'full' },
  { path: 'landingpage', component: LandingpageContainerComponent },
  { path: 'homepage', component: HomeContainersComponent },
  { path: 'twitch', component: StreamContainerComponent },
  { path: 'twitch/:live', component: LiveComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
