import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-navbar-container',
  templateUrl: './navbar-container.component.html',
  styleUrls: ['./navbar-container.component.scss']
})
export class NavbarContainerComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
    ($('#smile') as any).popup({on: 'hover'});
    ($('#games') as any).popup({on: 'click'});
  }
}
