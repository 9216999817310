import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  headerObj = {
    title: 'Unisciti a WanGames',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis egestas, neque fringilla efficitur suscipit, massa erat maximus mi, quis dapibus est est sed sem. Aliquam eleifend nec arcu a feugiat. In urna arcu, cursus at justo ac, lobortis ornare nisl. Aenean quis orci vel ante rhoncus dictum. Aliquam erat volutpat. Maecenas euismod arcu a massa convallis viverra. Proin auctor eget lectus molestie cursus',
    color: '008aff',
    color2: 'ff137d',
    img: './assets/img/iphone2.PNG',
    imgBackground: 'https://www.smartworld.it/wp-content/uploads/2020/09/Call-of-Duty-Black-Ops-Cold-War-multiplayer-14-1280x720.jpg',
    textButton: 'registrati adesso'
  };

  constructor() { }

  ngOnInit(): void {
  }

}
