<div class="container">
  <div class="ui secondary menu">
    <div class="item logo" routerLink="/landingpage">
      <img src="./assets/img/logo.PNG" class="ui circular image" alt="">
    </div>
    <a class="item hamburger">
      <i class="hamburger icon"></i>
    </a>
    <a class="item menu" routerLink="/homepage" routerLinkActive="active">
      Home
    </a>
    <a id="item menu games" class="item">
      <a class="">
        Games
        <i class="dropdown icon"></i>
      </a>
    </a>
    <a class="item menu twitch" [className]=""  routerLink="/twitch" routerLinkActive="active">
      Twitch
    </a>
    <a class="item menu" routerLinkActive="active">
      Help
    </a>
    <div class="right menu">
      <div class="item">
        <div class="ui action input">
          <input type="text" placeholder="Search...">
          <select class="ui compact selection dropdown">
            <option value="all">All</option>
            <option selected="" value="Fabbio">Fabbio</option>
            <option value="Tornei">Tornei</option>
            <option value="Ladder">Ladder</option>
            <option value="Player">Player</option>
            <option value="Giochi">Giochi</option>

          </select>
          <div class="ui button">
            <i class="search icon"></i>
          </div>
        </div>
      </div>
      <a class="circular ui icon item">
        <i class="user icon"></i>
      </a>
      <a class="circular ui icon item">
        <i class="store icon"></i>
      </a>
    </div>
  </div>

  <div class="ui fluid popup">
    <div class="ui four column relaxed equal height divided grid">
      <div class="column">
        <h4 class="ui header">FPS</h4>
        <div class="ui link list">
          <a class="item">Call Of Duty</a>
          <a class="item">Rainbow Siege 6</a>
          <a class="item">Fortnite</a>
          <a class="item">Rouge Company</a>
        </div>
      </div>
      <div class="column">
        <h4 class="ui header">Size</h4>
        <div class="ui link list">
          <a class="item">Small</a>
          <a class="item">Medium</a>
          <a class="item">Large</a>
          <a class="item">Plus Sizes</a>
        </div>
      </div>
      <div class="column">
        <h4 class="ui header">Colored</h4>
        <div class="ui link list">
          <a class="item">Neutrals</a>
          <a class="item">Brights</a>
          <a class="item">Pastels</a>
        </div>
      </div>
      <div class="column">
        <h4 class="ui header">Types</h4>
        <div class="ui link list">
          <a class="item">Knitwear</a>
          <a class="item">Outerwear</a>
          <a class="item">Pants</a>
          <a class="item">Shoes</a>
        </div>
      </div>
    </div>
  </div>
</div>
