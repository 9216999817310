import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  // tslint:disable-next-line:typedef
  openSidebar() {
    ($('.ui.sidebar') as any)
      .sidebar('setting', 'transition', 'overlay')
      .sidebar('toggle')
    ;
  }
}
