<div *ngIf="this.videoType === 1;else notLive">
  <div class="twitch-box live">
    <div class="title">
      <h1>IN <span>LIVE</span> ADESSO</h1>
    </div>
    <div class="twitch-video top right attached label">
      <carousel height="230">
        <div class="carousel-cell" *ngFor="let name of names; let i = index">
          <div class="twitch-video-carousel" [routerLink]="['/twitch', this.name]">
            <app-video [videoType]="1" [channel]="name" [id]="i"></app-video>
            <h1>{{name}}</h1>
          </div>
        </div>
      </carousel>
    </div>
  </div>
</div>
<ng-template #notLive>
  <carousel height="230">
    <div class="carousel-cell" *ngFor="let video of videos; let i = index">
      <div class="twitch-video-carousel" [routerLink]="['/twitch', this.video]">
        <app-video [videoType]="this.videoType" [idVideo]="video" [id]="i"></app-video>
        <h1>{{video}}</h1>
        <!-- <button class="twitch-btn">open</button> -->
      </div>
    </div>
  </carousel>
</ng-template>
