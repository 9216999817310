<section>
  <div class="container-content">
    <h2>{{this.headerObj.title}}</h2>
    <p>{{this.headerObj.text}}</p>
    <!--<button class="ui button">{{this.headerObj.textButton}}</button>
    -->
    <button class="ui button btn-grad">{{this.headerObj.textButton}}</button>
  </div>
  <div>
    <!-- <img class="xbox" src="./assets/img/Xbox_Series_X.png" alt=""> -->
    <img class="mobile" src="{{this.headerObj.img}}" alt="">
    <!-- <img class="ps" src="./assets/img/ps5_ps4.png" alt=""> -->
  </div>
  <svg class="wave" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
    <path fill="#fff" fill-opacity="1" d="M0,128L40,138.7C80,149,160,171,240,202.7C320,235,400,277,480,256C560,235,640,149,720,138.7C800,128,880,192,960,186.7C1040,181,1120,107,1200,90.7C1280,75,1360,117,1400,138.7L1440,160L1440,320L1400,320C1360,320,1280,320,1200,320C1120,320,1040,320,960,320C880,320,800,320,720,320C640,320,560,320,480,320C400,320,320,320,240,320C160,320,80,320,40,320L0,320Z" data-darkreader-inline-fill="" style="--darkreader-inline-fill:#007acc;"></path>
  </svg>
</section>
